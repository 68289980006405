import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard as UniAuthGuard, UniPage403Component, UniPage404Component, UniViewComponent } from '@unifonic/common';
import { AuthGuard } from '@shared/auth.guard';
import { AppLoggedComponent } from './layout/containers/app-logged/app-logged.component';

const routesForLoggedUsers: Routes = [
  { path: '', redirectTo: 'connectors', pathMatch: 'full' },
  { path: 'connectors', loadChildren: () => import('./marketplace/marketplace.module').then((m) => m.MarketplaceModule) },
  { path: 'connections-list', loadChildren: () => import('./connections/connections.module').then((m) => m.ConnectionsModule) },
  { path: 'super-admin', loadChildren: () => import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule) },
];

const errorRoutes: Routes = [
  {
    path: '403',
    component: UniPage403Component,
  },
  {
    path: '404',
    component: UniPage404Component,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

const routes: Routes = [
  {
    path: 'public',
    component: UniViewComponent,
    data: {
      isPublicView: true,
    },
    children: [{ path: '', loadChildren: () => import('./marketplace/marketplace.module').then((m) => m.MarketplaceModule) }],
  },
  {
    path: '',
    component: AppLoggedComponent,
    canActivate: [UniAuthGuard],
    canActivateChild: [AuthGuard],
    children: routesForLoggedUsers,
  },
  ...errorRoutes,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
