import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniCommonModule, UniSharedModule } from '@unifonic/common';
import { TranslateModule } from '@ngx-translate/core';
import { WorksWithProductsComponent } from './components/works-with-products/works-with-products.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClientTimeZonePipe } from './pipes/client-time-zone.pipe';

const components = [WorksWithProductsComponent];
const pipes = [ClientTimeZonePipe];
const modules = [CommonModule, UniCommonModule, UniSharedModule, TranslateModule];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [...modules, MatTooltipModule],
  exports: [...modules, ...components, ...pipes],
})
export class SharedModule {}
