import { Action } from '@ngrx/store';
import { Category, CreateCategoryRequest, GetCategoryResponse } from '../shared/category.model';

export namespace CategoryActions {
  export const SET_CATEGORY = '[category] SET_CATEGORY';
  export const SET_CATEGORY_SUCCESS = '[category] SET_CATEGORY_SUCCESS';
  export const DELETE_CATEGORY = '[category] SET_DELETE_CATEGORY';

  export class SetCategory implements Action {
    readonly type = SET_CATEGORY;
  }

  export class SetCategorySuccess implements Action {
    readonly type = SET_CATEGORY_SUCCESS;

    constructor(public response: GetCategoryResponse) {}
  }

  export class DeleteCategory implements Action {
    readonly type = DELETE_CATEGORY;

    constructor(public categoryId: string) {}
  }

  export type All = SetCategory | SetCategorySuccess;
}
