export enum ConnectionStatusEnum {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  DISCONNECTED = 'DISCONNECTED',
  DELETED = 'DELETED',
  INPROGRESS = 'INPROGRESS',
  RECONNECTED = 'RECONNECTED',
  DECLINED = 'DECLINED',
  UNINSTALLED = 'UNINSTALLED',
}
