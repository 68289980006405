import { Pipe, PipeTransform } from '@angular/core';
import momentTimezone from 'moment-timezone';

@Pipe({
  name: 'clientTimezone',
})
export class ClientTimeZonePipe implements PipeTransform {
  transform(dateString: string): string {
    const date = new Date(dateString);
    const [year, month, day, hours, minutes, seconds, milliSeconds] = [
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ];

    const saudiDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds, Math.floor(milliSeconds) / 1000));
    if (isNaN(saudiDate.getTime())) {
      return '';
    }
    const saudiDateString = saudiDate.toUTCString();
    const localTimeZone = momentTimezone.tz.guess();
    const saudiArabiaTime = momentTimezone.tz(saudiDateString, 'Asia/Riyadh');
    // this is to handle daylight saving time in Egypt
    // if (localTimeZone === 'Africa/Cairo') {
    //   saudiArabiaTime.add(1, 'hour');
    // }
    const localTime = saudiArabiaTime.clone().tz(localTimeZone);

    return localTime.format('D MMM, YYYY - hh:mm a');
  }
}
