<uni-nav>
  <uni-nav-header>
    <uni-nav-name>
      {{ "navigation.integrations.integrations" | translate }}
    </uni-nav-name>
    <uni-nav-back (click)="goBack()" *ngIf="hasBack"></uni-nav-back>
  </uni-nav-header>

  <ng-container *ngFor="let navigation of navigationList">
    <a routerLink="{{ navigation.path }}">
      <uni-nav-item [isSidebar]="true" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
        <uni-nav-item-icon>
          <uni-icon name="{{ navigation.icon }}"></uni-icon>
        </uni-nav-item-icon>
        <uni-nav-item-name>
          {{ navigation.name | translate }}
        </uni-nav-item-name>
      </uni-nav-item>
    </a>
  </ng-container>
</uni-nav>
