import { marketplaceInitialState, MarketplaceState } from './marketplace.state';
import { MarketplaceActions } from './marketplace.actions';

export function marketplaceReducer(state = marketplaceInitialState, action: MarketplaceActions.All): MarketplaceState {
  switch (action.type) {
    case MarketplaceActions.SET_APPLICATIONS_WITH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        applicationsWithProducts: action.response
          .filter(
            (application, index, array) =>
              array.findIndex(
                (x) => x.applicationId === application.applicationId && x.applicationVersion === application.applicationVersion
              ) === index
          ) // To remove duplicates till fixed from BE
          .sort((a, b) => a.applicationName?.localeCompare(b.applicationName))
          .map((application) => ({
            ...application,
            logo: application.logo?.replace(/^\//, 'https://app.apifuse.io/'),
          })),
      };
    }
    default: {
      return state;
    }
  }
}
