import { Component, HostBinding, Input } from '@angular/core';
import { UniNavFacade } from '@unifonic/common';

@Component({
  selector: 'int-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  @HostBinding('class.visible') @Input() isVisible: boolean = false;
  @Input() hasBack: boolean = false;

  navigationList = [
    // {
    //   path: '/connectors',
    //   name: 'navigation.integrations.connectors',
    //   icon: 'home',
    // },
    // {
    //   path: '/connections-list',
    //   name: 'navigation.integrations.myConnections',
    //   icon: 'plug',
    // },
    {
      path: '/super-admin',
      name: 'navigation.integrations.categories',
      icon: 'cogs',
    },
    {
      path: '/super-admin/application-manager',
      name: 'navigation.integrations.applications',
      icon: 'cogs',
    },
  ];

  constructor(private uniNavFacade: UniNavFacade) {}

  goBack(): void {
    this.uniNavFacade.setNavState(false);
  }
}
