import { Connection } from '../shared/connections.models';

export const CONNECTIONS_STORE_KEY = 'connections';

export interface ConnectionsState {
  connections: Connection[];
}

export const connectionsInitialState: ConnectionsState = {
  connections: [],
};
