import { InjectionToken } from '@angular/core';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MARKETPLACE_STORE_KEY, marketplaceInitialState, MarketplaceState } from '../marketplace/store/marketplace.state';
import { marketplaceReducer } from '../marketplace/store/marketplace.reducers';
import { MarketplaceEffects } from '../marketplace/store/marketplace.effects';
import { CATEGORY_STORE_KEY } from '../super-admin/store/category.state';
import { categoryReducer } from '../super-admin/store/category.reducers';
import { CategoryEffects } from '../super-admin/store/category.effects';
import { ConnectionsEffects } from '../marketplace/store/applicationsConnections/applicationConnections.effects';
import {
  APP_CONNECTIONS_STORE_KEY,
  appConnectionsReducer,
  applicationConnectionsInitialState,
} from '../marketplace/store/applicationsConnections/applicationConnections.reducers';
import { ApplicationConnectionsState } from '../marketplace/shared/applicationConnections/applicationConnections.models';
//  State
// ----------------------------------------
export interface AppState {
  [MARKETPLACE_STORE_KEY]: MarketplaceState;
  [APP_CONNECTIONS_STORE_KEY]: ApplicationConnectionsState;
}

export const initialState: AppState = {
  [MARKETPLACE_STORE_KEY]: marketplaceInitialState,
  [APP_CONNECTIONS_STORE_KEY]: applicationConnectionsInitialState,
};

// Reducers
// -----------------------------------------

export const reducers = {
  [MARKETPLACE_STORE_KEY]: marketplaceReducer,
  [CATEGORY_STORE_KEY]: categoryReducer,
  [APP_CONNECTIONS_STORE_KEY]: appConnectionsReducer,
};

//  Providers
// ----------------------------------------
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('app.reducer');

export const getReducers = () => reducers;

export const reducerProvider = {
  provide: REDUCER_TOKEN,
  useFactory: getReducers,
};

//  Modules
// ----------------------------------------
export const storeModule = [StoreModule.forRoot(REDUCER_TOKEN, { initialState })];

export const effectsModule = [EffectsModule.forRoot([MarketplaceEffects, CategoryEffects, ConnectionsEffects])];

export const storeDevtoolsModule = !environment.production ? [StoreDevtoolsModule.instrument({ maxAge: 50 })] : [];
