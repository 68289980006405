import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Environment, Permission, UniAuthFacade } from '@unifonic/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  constructor(private uniAuthFacade: UniAuthFacade, private router: Router) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.uniAuthFacade.isAdminLevel()) {
      if (route.url.toString().includes('connectors')) {
        this.router.navigate(['/super-admin']);
        return false;
      }
      return true;
    }

    if (
      !this.uniAuthFacade.hasPermission('FS.FLOWS', Permission.seeOwn) &&
      !this.uniAuthFacade.hasPermission('FS.FLOWS', Permission.createOwn) &&
      !this.uniAuthFacade.hasPermission('FS.FLOWS', Permission.editOwn) &&
      !this.uniAuthFacade.hasPermission('FS.FLOWS', Permission.deleteOwn)
    ) {
      window.location.href = Environment.getMainHost('flow');
      return false;
    }

    return true;
  }
}
