import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MarketplaceSelectors } from '../store/marketplaceSelectors';
import { MarketplaceState } from '../store/marketplace.state';
import { MarketplaceActions } from '../store/marketplace.actions';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApplicationWithTriggersAndActionsResponse } from './marketplace.models';
import { MarketplaceRepository } from './marketplace.repository';

@Injectable({ providedIn: 'root' })
export class MarketplaceFacade {
  applicationsWithProducts$ = this.store.pipe(select(MarketplaceSelectors.selectApplicationsWithProducts));

  constructor(private store: Store<MarketplaceState>, private repository: MarketplaceRepository) {}

  applicationWithProducts$ = (appId: string) =>
    this.applicationsWithProducts$.pipe(map((applications) => applications.find((application) => application.applicationId === appId)));

  setApplicationsWithProducts(): void {
    this.store.dispatch(new MarketplaceActions.SetApplicationsWithProducts());
  }

  getApplicationWithProducts(appId: string): void {
    this.store.dispatch(new MarketplaceActions.GetApplicationWithProducts(appId));
  }

  getApplicationWithTriggersAndActions(appId: string, appVersion: string): Observable<ApplicationWithTriggersAndActionsResponse> {
    return this.repository.getApplicationWithTriggersAndActions(appId, appVersion);
  }
}
