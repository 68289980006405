import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  AutoUnsubscribe,
  DateUtils,
  FeatureFlagKeys,
  HttpQueryFacade,
  UNI_AUTH_KEY,
  UniAuthService,
  UniFeatureFlagsService,
  UniLanguageService,
  UniLayoutFacade,
  UniUserpilotService,
} from '@unifonic/common';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CONNECTIONS_STORE_KEY } from './connections/store/connections.state';
import { MARKETPLACE_STORE_KEY } from './marketplace/store/marketplace.state';
import { CATEGORY_STORE_KEY } from './super-admin/store/category.state';
import { APP_CONNECTIONS_STORE_KEY } from './marketplace/store/applicationsConnections/applicationConnections.reducers';

@Component({
  selector: 'int-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateUtils.datepickerFormat },
  ],
})
export class AppComponent extends AutoUnsubscribe implements OnInit {
  isLoaderActive = true;
  isLoaderActive$ = combineLatest([
    this.uniLayoutFacade.isLoaderActive$,
    this.httpQueryFacade.isInProgress$(UNI_AUTH_KEY),
    this.httpQueryFacade.isInProgress$(CONNECTIONS_STORE_KEY),
    this.httpQueryFacade.isInProgress$(MARKETPLACE_STORE_KEY),
    this.httpQueryFacade.isInProgress$(CATEGORY_STORE_KEY),
    this.httpQueryFacade.isInProgress$(APP_CONNECTIONS_STORE_KEY),
  ]).pipe(map((loaders) => loaders.includes(true)));

  constructor(
    private uniLanguageService: UniLanguageService,
    private uniLayoutFacade: UniLayoutFacade,
    private cdr: ChangeDetectorRef,
    private uniAuthService: UniAuthService,
    private httpQueryFacade: HttpQueryFacade,
    private uniFeatureFlagService: UniFeatureFlagsService,
    private userpilotService: UniUserpilotService,
    @Optional() @Inject('environment') public environment
  ) {
    super();
    this.uniAuthService.clearUserData();
  }

  ngOnInit() {
    this.uniLanguageService.initLanguages();
    this.uniFeatureFlagService
      .getPermission$(FeatureFlagKeys.userpilot_2022_q_1, true)
      .pipe(take(1))
      .subscribe(() => {
        this.userpilotService.initialize(this.environment.userpilotToken);
      });
    this.initLoader();
  }

  initLoader() {
    this.subs.sink = this.isLoaderActive$.subscribe((isActive) => {
      this.isLoaderActive = isActive;
      this.cdr.detectChanges();
    });
  }
}
