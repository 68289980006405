import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@shared/settings.service';
import { Observable, of } from 'rxjs';
import { ConnectionAuditLog, ConnectionDetails, ConnectionEventLog, EventLogResponse } from './applicationConnections.models';
import { APP_CONNECTIONS_STORE_KEY } from '../../store/applicationsConnections/applicationConnections.reducers';
import { BaseResponseWithMessage } from 'src/app/models/base-response';

@Injectable({ providedIn: 'root' })
export class AppConnectionRepository {
  constructor(private http: HttpClient, private settingsService: SettingsService) {}

  getApplicationConnections(appId): Observable<ConnectionDetails[] | []> {
    return this.http.get<any>(`${this.settingsService.settings.apiBaseUrl}/api/v1/connection/details/application/${appId}`, {
      headers: {
        queryName: 'getApplicationConnections',
        queryGroups: [APP_CONNECTIONS_STORE_KEY],
      },
    });
  }

  getConnectionFullDetails(appId, appConnectionId): Observable<ConnectionDetails> {
    return this.http.get<any>(
      `${this.settingsService.settings.apiBaseUrl}/api/v1/connection/details/application/${appId}/connection/${appConnectionId}`,
      {
        headers: {
          queryName: 'getConnectionDetails',
          queryGroups: [APP_CONNECTIONS_STORE_KEY],
        },
      }
    );
  }

  getConnectionAuditLogs(appConnectionId): Observable<ConnectionAuditLog[] | []> {
    return this.http.get<any>(`${this.settingsService.settings.apiBaseUrl}/api/v1/audit/connection-events/${appConnectionId}`, {
      headers: {
        queryName: 'getConnectionAuditLogs',
        queryGroups: [APP_CONNECTIONS_STORE_KEY],
      },
    });
  }

  getConnectionEventLogs(appConnectionId: string, pageSize: number, pageNumber: number): Observable<EventLogResponse> {
    return this.http.get<any>(`${this.settingsService.settings.apiBaseUrl}/api/v1/event-triggers/connection/${appConnectionId}`, {
      params: {
        pageNumber: `${pageNumber}`,
        pageSize: `${pageSize}`,
      },
      headers: {
        queryName: 'getConnectionEventLogs',
        queryGroups: [APP_CONNECTIONS_STORE_KEY],
      },
    });
  }

  getEventLogDetails(eventId): Observable<ConnectionEventLog> {
    return this.http.get<any>(`${this.settingsService.settings.apiBaseUrl}/api/v1/event-triggers/${eventId}`, {
      headers: {
        queryName: 'getEventLogDetails',
        queryGroups: [APP_CONNECTIONS_STORE_KEY],
      },
    });
  }

  deleteConnection(connectionId: string): Observable<BaseResponseWithMessage> {
    return this.http.delete<BaseResponseWithMessage>(
      `${this.settingsService.settings.apiBaseUrl}/api/v1/middleware/workflow/connection/${connectionId}`,
      {
        headers: {
          queryName: 'deleteConnection',
          queryGroups: [APP_CONNECTIONS_STORE_KEY],
        },
      }
    );
  }

  editConnection(connectionId, newName): Observable<BaseResponseWithMessage> {
    return this.http.patch<BaseResponseWithMessage>(
      `${this.settingsService.settings.apiBaseUrl}/api/v1/connection/change-name/${connectionId}?name=${newName}`,
      {
        headers: {
          queryName: 'editConnection',
          queryGroups: [APP_CONNECTIONS_STORE_KEY],
        },
      }
    );
  }
}
