import { Action } from '@ngrx/store';
import { GetApplicationsWithProductsResponse } from '../shared/marketplace.models';

export namespace MarketplaceActions {
  export const SET_APPLICATIONS_WITH_PRODUCTS = '[marketplace] SET_APPLICATIONS_WITH_PRODUCTS';
  export const SET_APPLICATIONS_WITH_PRODUCTS_SUCCESS = '[marketplace] SET_APPLICATIONS_WITH_PRODUCTS_SUCCESS';
  export const GET_APPLICATION_WITH_PRODUCTS = '[marketplace] GET_APPLICATION_WITH_PRODUCTS';

  export class SetApplicationsWithProducts implements Action {
    readonly type = SET_APPLICATIONS_WITH_PRODUCTS;
  }

  export class SetApplicationsWithProductsSuccess implements Action {
    readonly type = SET_APPLICATIONS_WITH_PRODUCTS_SUCCESS;

    constructor(public response: GetApplicationsWithProductsResponse) {}
  }

  export class GetApplicationWithProducts implements Action {
    readonly type = GET_APPLICATION_WITH_PRODUCTS;

    constructor(public appId: string) {}
  }

  export type All = SetApplicationsWithProducts | SetApplicationsWithProductsSuccess | GetApplicationWithProducts;
}
