import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { MarketplaceActions } from './marketplace.actions';
import { MarketplaceRepository } from '../shared/marketplace.repository';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { MarketplaceFacade } from '../shared/marketplace.facade';
import { uniSnackbarActions } from '@unifonic/common';

@Injectable()
export class MarketplaceEffects {
  @Effect() setApplicationsWithProducts$ = this.actions$.pipe(
    ofType<MarketplaceActions.SetApplicationsWithProducts>(MarketplaceActions.SET_APPLICATIONS_WITH_PRODUCTS),
    mergeMap(() =>
      this.marketplaceRepository.getApplicationsWithProducts().pipe(
        mergeMap((response) => of(new MarketplaceActions.SetApplicationsWithProductsSuccess(response))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  @Effect() getApplicationWithProducts$ = this.actions$.pipe(
    ofType<MarketplaceActions.GetApplicationWithProducts>(MarketplaceActions.GET_APPLICATION_WITH_PRODUCTS),
    withLatestFrom(this.marketplaceFacade.applicationsWithProducts$),
    mergeMap(([{ appId }, applications]) =>
      applications.some((application) => application.applicationId === appId)
        ? EMPTY
        : of(new MarketplaceActions.SetApplicationsWithProducts())
    )
  );

  constructor(
    private actions$: Actions,
    private marketplaceRepository: MarketplaceRepository,
    private marketplaceFacade: MarketplaceFacade
  ) {}
}
