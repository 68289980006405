export enum ProductsEnum {
  FLOW_STUDIO = 'FLOW_STUDIO',
  AUDIENCE = 'AUDIENCE',
  CAMPAIGN = 'CAMPAIGN',
  CHATBOT = 'CHATBOT',
  CONTACT_CENTER = 'CONTACT_CENTER',
  ADMIN = 'ADMIN',
  AUTHENTICATE = 'AUTHENTICATE',
  REPORTS_AND_LOGS = 'REPORTS_AND_LOGS',
  ULINK = 'ULINK',
  CHANNELS = 'CHANNELS',
  DEVELOPERS = 'DEVELOPERS',
  NOTICE = 'NOTICE',
  LIBRARY = 'LIBRARY',
}

export const ProductsNames = {
  [ProductsEnum.CHATBOT]: 'Chatbot',
  [ProductsEnum.CAMPAIGN]: 'Campaigns',
  [ProductsEnum.FLOW_STUDIO]: 'Flow Studio',
  [ProductsEnum.CONTACT_CENTER]: 'Contact Center',
  [ProductsEnum.ADMIN]: 'Admin',
  [ProductsEnum.AUDIENCE]: 'Audiences',
  [ProductsEnum.AUTHENTICATE]: 'Authenticate',
  [ProductsEnum.CHANNELS]: 'Channels',
  [ProductsEnum.DEVELOPERS]: 'Developers',
  [ProductsEnum.NOTICE]: 'Notice',
  [ProductsEnum.REPORTS_AND_LOGS]: 'Reports And Logs',
  [ProductsEnum.ULINK]: 'ULink',
  [ProductsEnum.LIBRARY]: 'Library',
};

export const ProductsLogos = {
  [ProductsEnum.CHATBOT]: 'chatbot-logo',
  [ProductsEnum.CAMPAIGN]: 'campaigns-logo',
  [ProductsEnum.FLOW_STUDIO]: 'flow-studio-logo',
  [ProductsEnum.CONTACT_CENTER]: 'contact-center-logo',
  [ProductsEnum.ADMIN]: 'admin-logo',
  [ProductsEnum.AUDIENCE]: 'audiences-logo',
  [ProductsEnum.AUTHENTICATE]: 'authenticate-logo',
  [ProductsEnum.CHANNELS]: 'channels-logo',
  [ProductsEnum.DEVELOPERS]: 'developers-logo',
  [ProductsEnum.NOTICE]: 'notice-logo',
  [ProductsEnum.REPORTS_AND_LOGS]: 'reports-and-logs-logo',
  [ProductsEnum.ULINK]: 'ulink-logo',
  [ProductsEnum.LIBRARY]: 'library-logo',
};
