<uni-snackbar></uni-snackbar>
<ng-container *ngIf="isFeatureFlagFetched">
  <uni-nav-new *ngIf="isNewNav; else oldNav">
    <uni-view></uni-view>
  </uni-nav-new>

  <ng-template #oldNav>
    <uni-app-logged>
      <int-nav-wrapper></int-nav-wrapper>
    </uni-app-logged>
  </ng-template>
</ng-container>
