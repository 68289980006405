import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { NavWrapperComponent } from './components/nav-wrapper/nav-wrapper.component';
import { NavComponent } from './components/nav/nav.component';
import { AppLoggedComponent } from './containers/app-logged/app-logged.component';

const components = [NavComponent, NavWrapperComponent, AppLoggedComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, SharedModule, RouterModule],
  exports: [...components],
})
export class LayoutModule {}
