import { ApplicationWithProducts, Category } from '../shared/marketplace.models';

export const MARKETPLACE_STORE_KEY = 'marketplace';

export interface MarketplaceState {
  applicationsWithProducts: ApplicationWithProducts[];
  categories: Category[];
}

export const marketplaceInitialState: MarketplaceState = {
  applicationsWithProducts: [],
  categories: [],
};
