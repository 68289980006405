import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@shared/settings.service';
import { Observable } from 'rxjs';
import { BaseResponse } from '../../models/base-response';
import { Category, CreateCategoryRequest, GetCategoryResponse, UpdateCategoryRequest } from './category.model';
import { GetConnectionsResponse } from '../../connections/shared/connections.models';
import { CATEGORY_STORE_KEY } from '../store/category.state';

export enum CategoryQueryNames {
  GetCategories = 'getCategories',
  GetCategory = 'getCategory',
  CreateCategory = 'createCategory',
  UpdateCategory = 'updateCategory',
  DeleteCategory = 'deleteCategory',
}

@Injectable({ providedIn: 'root' })
export class CategoryRepository {
  constructor(private http: HttpClient, private settingsService: SettingsService) {}

  getCategories(): Observable<GetCategoryResponse> {
    return this.http.get<GetCategoryResponse>(`${this.settingsService.settings.apiBaseUrl}/api/v1/category/`, {
      headers: {
        queryName: CategoryQueryNames.GetCategories,
        queryGroups: [CATEGORY_STORE_KEY],
      },
    });
  }

  createCategory(category: CreateCategoryRequest): Observable<GetCategoryResponse> {
    return this.http.post<GetCategoryResponse>(`${this.settingsService.settings.apiBaseUrl}/api/v1/category/`, category, {
      headers: {
        queryName: CategoryQueryNames.CreateCategory,
        queryGroups: [CATEGORY_STORE_KEY],
      },
    });
  }

  deleteCategory(categoryId: string) {
    return this.http.delete(`${this.settingsService.settings.apiBaseUrl}/api/v1/category/${categoryId}`, {
      headers: {
        queryName: CategoryQueryNames.DeleteCategory,
        queryGroups: [CATEGORY_STORE_KEY],
      },
    });
  }

  updateCategory(category: UpdateCategoryRequest): Observable<GetCategoryResponse> {
    return this.http.put<GetCategoryResponse>(`${this.settingsService.settings.apiBaseUrl}/api/v1/category/`, category, {
      headers: {
        queryName: CategoryQueryNames.UpdateCategory,
        queryGroups: [CATEGORY_STORE_KEY],
      },
    });
  }

  getSingleCategory(categoryId: string): Observable<Category> {
    return this.http.get<Category>(`${this.settingsService.settings.apiBaseUrl}/api/v1/category/${categoryId}`, {
      headers: {
        queryName: CategoryQueryNames.GetCategory,
        queryGroups: [CATEGORY_STORE_KEY],
      },
    });
  }
}
