import { categoriesInitialState, CategoryState } from './category.state';
import { CategoryActions } from './category.actions';

export function categoryReducer(state = categoriesInitialState, action: CategoryActions.All): CategoryState {
  switch (action.type) {
    case CategoryActions.SET_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: action.response,
      };
    }
    default: {
      return state;
    }
  }
}
