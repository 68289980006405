import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@shared/settings.service';
import { Observable } from 'rxjs';
import {
  ApplicationWithTriggersAndActionsResponse,
  GetApplicationsResponse,
  GetApplicationsWithProductsResponse,
  GetCategoriesResponse,
} from './marketplace.models';
import { BaseResponse } from '../../models/base-response';
import { MARKETPLACE_STORE_KEY } from '../store/marketplace.state';

export enum MarketplaceQueryNames {
  GetApplications = 'getApplications',
  GetApplicationsWithProducts = 'getApplicationsWithProducts',
  GetCategories = 'getCategories',
}

@Injectable({ providedIn: 'root' })
export class MarketplaceRepository {
  constructor(private http: HttpClient, private settingsService: SettingsService) {}

  getApplications(): Observable<BaseResponse<GetApplicationsResponse>> {
    return this.http.get<BaseResponse<GetApplicationsResponse>>(
      `${this.settingsService.settings.apiBaseUrl}/api/v1/middleware/workflow/applications`,
      {
        headers: {
          queryName: MarketplaceQueryNames.GetApplications,
          queryGroups: [MARKETPLACE_STORE_KEY],
        },
      }
    );
  }

  getApplicationsWithProducts(): Observable<GetApplicationsWithProductsResponse> {
    return this.http.get<GetApplicationsWithProductsResponse>(`${this.settingsService.settings.apiBaseUrl}/api/v1/application/product/`, {
      headers: {
        queryName: MarketplaceQueryNames.GetApplicationsWithProducts,
        queryGroups: [MARKETPLACE_STORE_KEY],
      },
    });
  }

  getApplicationWithTriggersAndActions(appId: string, appVersion: string): Observable<ApplicationWithTriggersAndActionsResponse> {
    return this.http.get<ApplicationWithTriggersAndActionsResponse>(
      `${this.settingsService.settings.apiBaseUrl}/api/v1/workflow/events/connector-details`,
      {
        params: { appId, appVersion },
        headers: {
          queryName: MarketplaceQueryNames.GetApplicationsWithProducts,
          queryGroups: [MARKETPLACE_STORE_KEY],
        },
      }
    );
  }

  getCategories(): Observable<GetCategoriesResponse> {
    return this.http.get<GetCategoriesResponse>(`${this.settingsService.settings.apiBaseUrl}/api/v1/categories`, {
      headers: {
        queryName: MarketplaceQueryNames.GetCategories,
        queryGroups: [MARKETPLACE_STORE_KEY],
      },
    });
  }
}
