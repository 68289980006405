import { Category } from '../shared/category.model';

export const CATEGORY_STORE_KEY = 'category';

export interface CategoryState {
  categories: Category[];
}

export const categoriesInitialState: CategoryState = {
  categories: [],
};
