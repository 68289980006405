import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { uniSnackbarActions } from '@unifonic/common';
import { CategoryActions } from './category.actions';
import { CategoryRepository } from '../shared/category.repository';

@Injectable()
export class CategoryEffects {
  @Effect() setCategory$ = this.actions$.pipe(
    ofType<CategoryActions.SetCategory>(CategoryActions.SET_CATEGORY),
    mergeMap(() =>
      this.categoryRepository.getCategories().pipe(
        mergeMap((response) => of(new CategoryActions.SetCategorySuccess(response))),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault')))
      )
    )
  );

  constructor(private actions$: Actions, private categoryRepository: CategoryRepository) {}

  @Effect() deleteCategory = this.actions$.pipe(
    ofType<CategoryActions.DeleteCategory>(CategoryActions.DELETE_CATEGORY),
    mergeMap(({ categoryId }) =>
      this.categoryRepository.deleteCategory(categoryId).pipe(
        mergeMap(() => of(new CategoryActions.SetCategory())),
        catchError(() => of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDependency')))
      )
    )
  );
}
