import { ConnectionStatusEnum } from '@shared/enums/connection-status.enum';
import { ApplicationConnectionsState } from '../../shared/applicationConnections/applicationConnections.models';
import { ApplicationConnectionsActions } from './applicationConnections.actions';

export const APP_CONNECTIONS_STORE_KEY = 'app-connections';

export const applicationConnectionsInitialState: ApplicationConnectionsState = {
  connections: [],
  eventLogs: {},
};

export function appConnectionsReducer(
  state: ApplicationConnectionsState = applicationConnectionsInitialState,
  action: ApplicationConnectionsActions.All
): ApplicationConnectionsState {
  switch (action.type) {
    case ApplicationConnectionsActions.SET_APPLICATION_CONNECTIONS_SUCCESS: {
      return {
        ...state,
        connections: action.connectionDetailsArr?.filter((connection) => ConnectionStatusEnum.ACTIVE === connection.status),
      };
    }
    case ApplicationConnectionsActions.SET_CONNECTION_FULL_DETAILS_SUCCESS: {
      return {
        ...state,
        connections: state.connections.map((connection) =>
          connection.appConnectionId === action.connectionFullDetails.appConnectionId ? action.connectionFullDetails : connection
        ),
      };
    }
    case ApplicationConnectionsActions.SET_CONNECTION_AUDIT_LOGS_SUCCESS: {
      return {
        ...state,
        connections: state.connections.map((connection) => {
          if (connection.appConnectionId === action.appConnectionId) {
            connection.auditLogs = action.connectionAuditLogArr;
          }
          return connection;
        }),
      };
    }
    case ApplicationConnectionsActions.SET_CONNECTION_EVENT_LOGS_SUCCESS: {
      return {
        ...state,
        connections: state.connections.map((connection) => {
          if (connection.appConnectionId === action.appConnectionId) {
            const { eventLogs = { content: [] } } = connection;
            connection.eventLogs = {
              ...action.eventLogResponse,
              content: [...eventLogs.content, ...action.eventLogResponse.content],
            };
          }
          return connection;
        }),
      };
    }
    case ApplicationConnectionsActions.SET_EVENT_LOG_DETAILS_SUCCESS: {
      return {
        ...state,
        eventLogs: {
          ...state.eventLogs,
          [action.eventId]: { eventDetails: action.eventDetails, mappedDetails: EventkeyValuePair(action.eventDetails) },
        },
      };
    }
    case ApplicationConnectionsActions.RESET_APPLICATION_CONNECTIONS: {
      return applicationConnectionsInitialState;
    }
    case ApplicationConnectionsActions.RESET_CONNECTIONS_EVENT_LOGS: {
      return {
        ...state,
        connections: state.connections.map((connection) => {
          if (connection.appConnectionId === action.appConnectionId) {
            connection.eventLogs = { content: [] };
          }
          return connection;
        }),
      };
    }
    default: {
      return state;
    }
  }
}

function EventkeyValuePair(json, formattedJson = {}): Object {
  if (!json) {
    return formattedJson;
  }
  Object.keys(json).forEach((key) => {
    if (json[key] instanceof Object) {
      EventkeyValuePair(json[key], formattedJson);
    } else {
      formattedJson[key] = json[key];
    }
  });

  return formattedJson;
}
