import { forkJoin, from, Observable } from 'rxjs';

import { TranslateLoader } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return forkJoin([
      from(import(`../assets/i18n/${lang}.json`)),
      from(import(`../../unifonic-common/src/modules/uni-nav-new/shared/translations.json`)),
    ]).pipe(map(([projectTranslations, unifonicNavTranslations]) => mergeDeep({ ...projectTranslations }, { ...unifonicNavTranslations })));
  }
}

function mergeDeep(target: { [x: string]: any }, source: { [x: string]: any }) {
  const isObject = (obj: { [x: string]: any }) => obj && typeof obj === 'object';
  if (!isObject(target) || !isObject(source)) {
    return source;
  }
  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];
    if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else if ((!isObject(targetValue) && isObject(sourceValue)) || !targetValue) {
      target[key] = sourceValue;
    }
  });
  return target;
}
