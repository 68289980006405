<div class="application__products" *ngIf="products && products.length">
  <ng-container *ngIf="tableView && products.length > 2">
    <div class="uni-tag-container">
      <img
        [alt]="productsNames[products[0].product]"
        [matTooltip]="productsNames[products[0].product]"
        [src]="'assets/logos/' + productsLogos[products[0].product] + '.png'"
        class="tag-logo"
      />
      <uni-text-body [weight]="uniTextWeight.regular" [isUppercase]="false">{{ productsNames[products[0].product] }} </uni-text-body>
    </div>
    <div class="uni-tag-container">
      <img
        [alt]="productsNames[products[1].product]"
        [matTooltip]="productsNames[products[1].product]"
        [src]="'assets/logos/' + productsLogos[products[1].product] + '.png'"
        class="tag-logo"
      />
      <uni-text-body [weight]="uniTextWeight.regular" [isUppercase]="false">{{ productsNames[products[1].product] }} </uni-text-body>
    </div>
    <div class="uni-tag-container">
      <!-- <img
        [alt]="productsNames[product.product]"
        [matTooltip]="productsNames[product.product]"
        [src]="'assets/logos/' + productsLogos[product.product] + '.png'"
        class="tag-logo"
      /> -->
      <uni-text-body [weight]="uniTextWeight.regular" [isUppercase]="false"> +{{ products.length - 2 }} </uni-text-body>
    </div>
  </ng-container>
  <ng-container *ngIf="!tableView || products.length <= 2">
    <div *ngFor="let product of products" class="uni-tag-container">
      <img
        [alt]="productsNames[product.product]"
        [matTooltip]="productsNames[product.product]"
        [src]="'assets/logos/' + productsLogos[product.product] + '.png'"
        class="tag-logo"
      />
      <uni-text-body [weight]="uniTextWeight.regular" [isUppercase]="false">{{ productsNames[product.product] }} </uni-text-body>
    </div>
  </ng-container>
</div>
