import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UniTextWeight } from '@unifonic/common';
import { Product } from '../../../marketplace/shared/marketplace.models';
import { ProductsEnum, ProductsLogos, ProductsNames } from '@shared/enums/products.enum';

@Component({
  selector: 'int-works-with-products',
  templateUrl: './works-with-products.component.html',
  styleUrls: ['./works-with-products.component.scss'],
})
export class WorksWithProductsComponent implements OnChanges {
  @Input() products: Product[] = [];
  @Input() tableView: boolean = false;
  productsNames = ProductsNames;
  productsLogos = ProductsLogos;
  uniTextWeight = UniTextWeight;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.products && this.products && this.products.length) {
      this.products.forEach((product) => {
        if (!product.product) {
          product.product = ProductsEnum.FLOW_STUDIO; // Work around until null product changed
        }
      });
    }
  }
}
