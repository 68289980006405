import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UniSnackbarFacade } from '@unifonic/common/src';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private uniSnackbarFacade: UniSnackbarFacade) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // will handle special cases and special error messages later //
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.uniSnackbarFacade.show('error', 'snackbar.errorDefault');

        return of({} as HttpEvent<any>);
      })
    );
  }
}
